@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

#root {
  box-sizing: border-box;
  /* padding: 16px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* div {
  flex: 1 1 auto;
} */

.rbc-time-header {
  display: none;
  border-bottom: none;
}

.rbc-time-content {
  border-top: none;
}

.rbc-day-slot {
  background-color: #fff;
}

.rbc-time-view {
  border-left: none;
  border-right: none;
}

.rbc-timeslot-group,
.rbc-events-container {
  border-left: none;
}

.rbc-event,
.rbc-selected {
  color: #bf0d3e;
  font-weight: bold;
  border: none !important;
  background-color: rgba(216, 53, 74, 0.2) !important;
  width: 100% !important;
  position: initial !important;
}

.rbc-timeslot-group .rbc-time-slot:nth-child(2) {
  display: none;
}

.rbc-timeslot-group .rbc-time-slot:nth-child(1) {
  align-items: center;
  display: flex;
}

.rbc-events-container {
  display: flex;
  column-gap: 6px;
}
